import dayjs from 'dayjs'
import { isEmpty, cloneDeep } from 'lodash-es'
import { useEntityStore } from '../../entity'
import { useAccountStore } from '../../accounts'
import { useMenuGlobalStore } from '../../global/menu'

import { processTableFieldList } from '@/config/reports'
import indexCell from '@/components/Reports/Cell/IndexCell.vue'
import subHeaderCell from '@/components/Reports/Cell/SubHeaderCell.vue'

// 加载字段配置
const configCellModules: Record<string, any> = import.meta.glob('./*.ts', {
  eager: true
})
const configCellModulesMap = new Map()
Object.keys(configCellModules).forEach((modules) => {
  const key = configCellModules[modules]
  if (!isEmpty(key.default)) {
    configCellModulesMap.set(key.default?.type, key.default?.config)
  }
})
// 表格展示内容
// eslint-disable-next-line no-unused-vars
const tableValueGetter = (field: any, type: string) => {
  return (params: any) => {
    const data: any = { cellName: field.name, tableType: type, ...params.data }
    if (field.type === 'CurrencyBalance') {
      data.symbol = field?.symbol ?? ''
    }
    return data
  }
}
const tableWidthGetter = (item: any) => {
  let width = 200
  if (
    (item.name === 'Description' && item?.fieldId !== 'defi-description') ||
    item.name === 'Address' ||
    item.name === 'From/To' ||
    item.name === 'Asset Symbol'
  ) {
    width = 500
  } else if (item.name === 'Address/Bank Account') {
    width = 170
  } else if (item.type === 'CurrencyBalance') {
    width = item.name === 'Other currencies' ? 120 : 100
  } else if (item.type === 'Number' && item.name === 'Total') {
    width = 110
  } else if (item.name === 'Datasource provider') {
    width = 180
  } else if (item.name === 'Memo') {
    width = 500
  } else if (item.name === 'emptyTitle') {
    width = 80
  } else if (['defi-account-Procotol', 'defi-pool', 'defi-type', 'defi-description'].includes(item?.fieldId)) {
    width = 140
  } else if (['defi-supply', 'defi-pending-rewards', 'defi-borrow'].includes(item?.fieldId)) {
    width = 160
  }
  return width
}

export const useReportsTableConfigStore = defineStore({
  id: 'reportsTableConfigStore',
  state: () => {
    const reportsTableConfigState: {
      processTableConfig: any[]
    } = {
      processTableConfig: []
    }
    return reportsTableConfigState
  },
  actions: {
    /**
     * 获取过程表配置
     * @param {string} type 表格类型(generalLedger、transactions、holdings、accounts、contacts)
     * @returns
     */
    getProcessTableConfig(type: string, pinnedList: string[] = [], pinned: string = 'left', extra: any = {}) {
      const fieldGroupList = cloneDeep(processTableFieldList[type].fieldGroupList)
      const accountStore = useAccountStore()
      const menuGlobalStore = useMenuGlobalStore()
      const entityStore = useEntityStore()

      const isToday = dayjs().tz(entityStore.entityDetail?.timezone).format('YYYY-MM-DD') === accountStore.datetime
      const { isOpenState } = storeToRefs(menuGlobalStore)
      let clientCurrencyWidth = (document.documentElement.clientWidth || document.body.clientWidth) - 516
      clientCurrencyWidth = isOpenState.value ? clientCurrencyWidth - 180 : clientCurrencyWidth - 64
      const tableConfig: any[] = [
        // 定义索引和分组字段配置
        {
          headerName: '',
          colId: '#',
          field: '#',
          suppressMenu: false,
          headerComponent: subHeaderCell,
          cellRenderer: indexCell,
          suppressAutoSize: false,
          suppressSizeToFit: true,
          pinned:
            type === 'accounts' &&
            accountStore.treasuryBalanceList.total.currencyList.length * 100 > clientCurrencyWidth
              ? 'left'
              : null,
          valueGetter: tableValueGetter({}, type),
          width: 36,
          cellStyle: {
            padding: 0
          },
          colSpan: (params: any) => {
            const rowData = params.node
            if (rowData.rowPinned === 'bottom' && rowData.rowIndex === 0) {
              return 2
            }
            if (rowData.data?.type === 'total') {
              return 8
            }
            return 1
          },
          resizable: false // 是否开启调整列大小，就是拖动改变列大小
        }
      ]

      if (isEmpty(fieldGroupList)) {
        return tableConfig
      }
      if (type === 'accounts') {
        const currencyFieldList = accountStore.treasuryBalanceList.total.currencyList.map((item) => {
          return {
            fieldId: `accounts-${item.underlyingCurrency.underlyingCurrencyId}`,
            symbol: item.underlyingCurrency.symbol,
            name: item?.underlyingCurrency?.showSymbol ?? item.currency,
            type: 'CurrencyBalance'
          }
        })
        fieldGroupList.push(...currencyFieldList)
        fieldGroupList.push({
          fieldId: `accounts-total`,
          name: 'Total',
          type: 'Number',
          pinned: 'right'
        })
        // 是否为今天
        if (isToday) {
          fieldGroupList.push({
            fieldId: `accounts-operating`,
            name: 'emptyTitle',
            type: 'Operating',
            pinned: 'right'
          })
        }
      }
      // 处理字段分组和字段展示
      fieldGroupList?.forEach((fieldItem: any, i: number) => {
        // 根据字段类型找到具体的字段配置
        const config = configCellModulesMap.get(fieldItem.type) ?? {}
        // 拼接分组数据
        const formatField: any = {
          headerName: fieldItem.name, // 标题名
          headerComponent: subHeaderCell,
          headerTooltip: '',
          field: fieldItem.fieldId, // 数据
          colId: fieldItem.fieldId,
          rowGroup: false,
          // eslint-disable-next-line no-nested-ternary
          pinned:
            // eslint-disable-next-line no-nested-ternary
            (type === 'accounts' &&
              pinnedList.includes(fieldItem.name) &&
              accountStore.treasuryBalanceList.total.currencyList.length * 100 > clientCurrencyWidth) ||
            (type !== 'accounts' && pinnedList.includes(fieldItem.name))
              ? pinned
              : (fieldItem.name === 'Total' || fieldItem.name === 'emptyTitle') &&
                  type === 'accounts' &&
                  (accountStore.treasuryBalanceList.total.currencyList.length >= 16 ||
                    accountStore.treasuryBalanceList.total.currencyList.length * 100 > clientCurrencyWidth)
                ? 'right'
                : null,
          width: tableWidthGetter(fieldItem),
          suppressSizeToFit: fieldItem.name !== 'From/To',
          suppressAutoSize: fieldItem.name === 'From/To' || fieldItem.name === 'Asset Symbol',
          columnTypes: fieldItem.type,
          valueGetter: tableValueGetter(fieldItem, type),
          columnIndex: i,
          hide: false,
          colSpan: (params: any) => {
            // eslint-disable-next-line no-unused-vars
            const rowData = params.node
            if (rowData?.rowPinned === 'top' && params?.column?.colId === 'derivative-asset-symbol') {
              return 4
            }
            if (rowData?.group && type === 'derivative' && params?.column?.colId === 'derivative-asset-symbol') {
              return 4
            }
            if (rowData?.rowPinned === 'top' && params?.column?.colId === 'defi-account-Procotol') {
              return 7
            }
            if (rowData?.group && type === 'deFiPosition' && params?.column?.colId === 'defi-account-Procotol') {
              return 7
            }
            if (rowData?.group && type === 'accounts' && params?.column?.colId === 'accounts-name') {
              return 2
            }
            return 1
          },
          ...config
        }
        tableConfig.push(formatField)
      })
      // 分组字段的配置
      if (['accounts', 'derivative', 'holdings', 'deFiPosition'].includes(type)) {
        tableConfig.push({
          headerName: 'firstLevelGroup',
          field: 'firstLevelGroup',
          rowGroup: true,
          showRowGroup: true,
          suppressAutoSize: false,
          suppressSizeToFit: true,
          hide: true
        })
        if (extra?.subGroup !== 'NONE' && type !== 'holdings') {
          tableConfig.push({
            headerName: 'secondLevelGroup',
            field: 'secondLevelGroup',
            rowGroup: true,
            showRowGroup: true,
            suppressAutoSize: false,
            suppressSizeToFit: true,
            hide: true
          })
        }
      }
      if (pinnedList.includes('Operating')) {
        // 根据字段类型找到具体的字段配置
        const config = configCellModulesMap.get('Operating') ?? {}
        // 拼接分组数据
        const formatField: any = {
          headerName: 'Operating', // 标题名
          headerComponent: subHeaderCell,
          headerTooltip: '',
          field: 'Operating',
          colId: 'Operating',
          rowGroup: false,
          pinned: 'right',
          width: 90,
          suppressAutoSize: false,
          suppressSizeToFit: true,
          columnTypes: 'Operating',
          valueGetter: tableValueGetter({}, type),
          hide: false,
          ...config
        }
        tableConfig.push(formatField)
      }
      this.processTableConfig = tableConfig
      return tableConfig
    }
  }
})
