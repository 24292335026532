<template>
  <div class="elv-financials-cell-currency-balance">
    <div class="elv-financials-cell-currency-balance__value">
      <div
        v-if="props.params.value?.cellName !== 'Other currencies'"
        :title="`${currencyData?.amount !== null ? formatNumber(currencyData?.amount, 20) : '-'}`"
      >
        {{ currencyData?.amount !== null ? formatNumber(currencyData?.amount ?? 0, 2) : '-' }}
      </div>
      <p
        v-if="currencyData?.amountFC !== null"
        :class="{ 'is-other': props.params.value?.cellName === 'Other currencies' }"
        :title="`${fieldValueFormat(
          currencyData?.amountFC ?? 0,
          {
            price: true,
            keepPoint: true,
            symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
          },
          'NUMBER'
        )}`"
      >
        {{
          fieldValueFormat(
            currencyData?.amountFC ?? 0,
            {
              dollar: true,
              symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            },
            'NUMBER'
          )
        }}
      </p>
    </div>
    <el-tooltip
      v-if="props.params.node?.rowPinned !== 'top' && currencyData?.failCount"
      effect="dark"
      placement="top"
      popper-class="elv-report-table-tips"
      overlay-classname="elv-report-table-tips"
      :show-after="500"
    >
      <div class="elv-financials-cell-currency-balance-verify">
        <img src="@/assets/img/reports/verify-info.png" alt="filled" />
      </div>

      <template #content>
        <p>{{ t('message.balanceVerificationFailed') }}</p>
      </template>
    </el-tooltip>
  </div>
</template>

<script setup lang="ts">
import { keyBy } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { useEntityStore } from '@/stores/modules/entity'
import { formatNumber, fieldValueFormat } from '@/lib/utils'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const { t } = useI18n()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const currencyData = computed(() => {
  const currencyList =
    props.params.node?.rowPinned === 'top'
      ? props.params?.value?.fieldValues?.currencyList
      : props.params?.data?.currencyList
  const newList = keyBy(currencyList, 'currency')
  return newList[props.params?.value?.symbol] ?? {}
})
</script>

<style lang="scss">
.elv-financials-cell-currency-balance {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  font-family: 'Barlow';
  position: relative;

  .elv-financials-cell-currency-balance__value {
    color: #0e0f11;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    text-align: right;

    p {
      color: #838d95;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;

      &.is-other {
        font-family: 'Barlow';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
      }
    }
  }

  .elv-financials-cell-currency-balance-verify {
    position: absolute;
    right: -10px;
    top: -7px;

    img {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}
</style>
